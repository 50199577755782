.card100 {
    width: 78%;
    margin-right: 10%;
    height: 140px;
    margin-top: 6px;
    transition: 0.5s;
    display: grid;
    grid-template-columns: 30% auto;
    z-index: 0;
    background-color: transparent;
    background: none;
}

.image {
    /* border-radius: 20px 20px 20px 20px; */
    width: 100%;
    height: 140px;
    display: inline-block;
    border-bottom: white 6px solid;
    border-top: white 6px solid;
    border-width: 0;
    line-height: 100px;
    color: transparent;
    font-weight: 400;
    background-size: cover;
    background-position: center center;
    transition: 1s;
    cursor: pointer;
    background-color: white;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); */
    z-index: 10;
    position: relative;
}

.image2 {
    width: 100%;
    height: 140px;
    display: inline-block;
    object-fit: cover;
    object-position: center;
    transition: 1s;
}

.card100:hover .image {
    /* height: 200px; */
    /* margin-top: -60px; */
    border-width: 3px;
    z-index: 30;
    color: #c00000;
    background-size: cover;
    background-position: center center;
    box-shadow: 5px 5px 10px rgba(1, 1, 1, 1);
    box-shadow: 1px 1px 10px 5px rgba(1, 1, 1, 1);

}

.text {
    /* border-radius: 10px 10px 10px 10px; */
    border: #c00000 2px solid;
    background-color: white;
    /* padding: 5px; */
    min-width: max-content;
    position: absolute;
    top: 0;
    /* right: -22%; */
    left: 67.5%;
    z-index: 35;
    color: #c00000;
}

/* .kav {
    margin-top: 3%;
    background-color: black;
    height: 1px;
    width: 100%;
    text-align: center;
    transition: 1s;
    z-index: 1;
} */

/* .kav1 {
    width: 80%;
    background-color: #c00000;
} */

.border {
    position: absolute;
    width: 0px;
    height: 140px;
    border: #c00000 1px solid;
    top: 0;
    left: 0;
    margin-left: 0;
    z-index: 19;
    border-right: none;
    transition: 1s;
    /* margin: 0px 0px 0px 0px; */
}

.card100:hover .border {
    width: 100%;
}

.border2 {
    position: absolute;
    width: 0;
    height: 80%;
    border: #c00000 2px solid;
    top: 10%;
    left: 10%;
    z-index: 20;
    border-right: none;
    border-left: none;
    transition: 1s;
}

.card100:hover .border2 {
    width: 80%;
}

.border3 {
    position: absolute;
    width: 80%;
    height: 0;
    border: #c00000 2px solid;
    top: 10%;
    left: 10%;
    z-index: 20;
    border-top: none;
    border-bottom: none;
    transition: 1s;
}

.card100:hover .border3 {
    height: 80%;
}

/* .containe0 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-left: 10%;
    margin-right: 10%;
} */

.containeNew0 {
    width: 100%;
    padding-right: 33%;
    padding-bottom: 20px;
    padding-left: 12%;
    background-color: white;
    z-index: 20;
    border-bottom: #c00000 2px solid;
}

.containeNew {
    display: inline-block;
    width: 100%;
    transition: 1s;
    border-color: #c00000;
    height: 140px;
    position: relative;
    background-color: transparent;
    z-index: 0;
    background: none;
    /* align-items: center;
    justify-content: center; */
    background-position-y: center;
    transition: 1s;
    text-align: center;
    display: flex;
    align-items: center;
}

/* .containeNew:hover {
    border-top: #c00000 1px solid;
    border-bottom: #c00000 1px solid;
} */

.con {
    /* background-image: url(../../../pic/רקע.jpg); */
    background-color: rgb(245, 238, 238);
    z-index: 1;
}

.sticky {
    position: fixed;
    top: 0;
    padding-top: 5%;
    width: 100%;
    z-index: 20;
    display: inline-block;
    left: 0;
}

.center {
    width: 25%;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 90%;
    vertical-align: top; /* מיישר כלפי מעלה */
    /* margin-top: auto;
    margin-bottom: auto; */
    /* display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; */
}

/* .containe p {
    max-width: 30px;
    height: 80px;
} */

.black {
    position: absolute;
    line-height: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.743);
    opacity: 0;
    transition: 1s;
    color: #c00000;
}

.card100:hover .black {
    opacity: 1;
    color: white;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: #c00000;
}

.show {
    display: inline-block;
}

@media (max-height: 760px) {
    .containe {
        margin-top: 11%;
        z-index: 1;
        height: 43vh;
        width: 100vh;
    }

    .containe0 {
        margin-right: 2%;
    }
}

@media (max-width: 1300px) {
    .text {
        width: 150px;
        min-width: max-content;
        /* left: 5%; */
    }
}

@media (max-width: 768px) {
    .card100 {
        width: 96%;
        margin-right: 4%;
    }

    .containeNew0 {
        padding-left: 0%;
        padding-bottom: 5px;
    }

    .show {
        display: none;
    }

    #sortAndFilter {
        width: 40px;
    }

    .notBus {
        margin-top: 55%;
        margin-bottom: 15%;
    }
}